import api from '@/apis/BaseApi';
import vm from '@/main';
import { quizModel} from "@/models/WorkspaceModel";

export default class WorkspaceQuizApi {
    static async getQuizByName(name:string, pageNumber:number): Promise<string> {
        const result = await api.get(`/workspace/quiz-groups/?name=${name}&pageNumber=${pageNumber}&pageSize=20`,);
        return result.data;
    }

    static async getQuizById(id:string): Promise<string> {
        const result = await api.get(`/workspace/quiz-groups/${id}`,);
        return result.data;
    }
    static async deleteQuiz(quizGroupId:string): Promise<string> {
        const result = await api.delete(`/workspace/quiz-groups/${quizGroupId}`,);
        return result.data;
    }
    // static async deleteQuiz(quizModel:quizModel): Promise<void> {
        // const result = await api.delete(`/workspace/workspace/object/${quizModel.uuid}`, {name: quizModel.metadata.name, shuffleOptions: quizModel.shuffleOptions, shuffleQuizzes: quizModel.shuffleQuizzes, categoryNodeId:quizModel.categoryIds, imageUrl: quizModel.showImageUrl});
        // return result.data; q
    // }
    static async updateQuiz(quizModel:quizModel): Promise<string> {
        let params: { [key: string]: any } = {};
        console.log("quizModel categoryIds = " + quizModel.categoryIds)

        // 檢查每個欄位，若不為空則加入到參數物件中
        if (quizModel.metadata.name!="") {
            params.name = quizModel.metadata.name;
        }
        if (quizModel.imageUrl!="") {
            params.imageUrl = quizModel.imageUrl;
        }
        if (quizModel.categoryIds!="") {
            params.categoryNodeId = quizModel.categoryIds;
        }
        params.shuffleOptions = quizModel.shuffleOptions;
        params.shuffleQuizzes = quizModel.shuffleQuizzes;
        // const result = await api.post(`/workspace/quiz-groups/${quizModel.uuid}`, {name: quizModel.metadata.name, shuffleOptions: quizModel.shuffleOptions, shuffleQuizzes: quizModel.shuffleQuizzes, categoryNodeId:quizModel.categoryIds, imageUrl: quizModel.imageUrl});
        const result = await api.post(`/workspace/quiz-groups/${quizModel.uuid}`, params);
        return result.data;
    }
    static async createQuiz(name:string, parentId:string): Promise<string> {
        const result = await api.post(`/workspace/quiz-groups`, {name: name, parentId: parentId});
        return result.data;
    }
    static async downloadQuizGroup(uuid:string){
        const result = await api.get(`/workspace/quiz-groups/${uuid}/csv_download`);
        return result.data;
    }
    static async listCSVMedia(uuid:string, formData:FormData): Promise<string>{
        const result = await api.post(`/workspace/quiz-groups/${uuid}/list_csv_media`, formData);
        return result.data;
    }
    static async mediaUpload(uuid:string, formData:FormData){
        const result = await api.post(`/workspace/quiz-groups/${uuid}/media_upload`, formData);
        return result.data;
    }
    static async csvUpload(uuid:string, formData:FormData): Promise<string>{
        const result = await api.post(`/workspace/quiz-groups/${uuid}/csv_upload`, formData);
        return result.data;
    }
}