
    import { defineComponent } from "vue";
    import vm from '@/main';
    import axios, { AxiosError } from 'axios';
    import { quizModel} from "@/models/WorkspaceModel";
    import AnswerGroupResult from "@/components/AnswerGroupResult.vue";
    import CategoryNodePath from "@/components/CategoryNodePath.vue";
    import WorkspaceQuizApi from "@/apis/WorkspaceQuizApi";
    import AnswerGroupApi from '@/apis/AnswerGroupApi';
    import Papa from 'papaparse'
    interface InputFileEvent extends Event {
        target: HTMLInputElement;
    }
    export default defineComponent({
        name: "EditQuiz",
        components: {
            AnswerGroupResult,
            CategoryNodePath
        },
        data() {
            return {
                currentquiz: {
                    imageUrl: '',
                    uuid: '',
                    metadata: {name: "",type: "" },
                    shuffleOptions: false,
                    shuffleQuizzes: false,
                    categoryIds: '',
                    CategoryNodePath: ''
                } as quizModel,
                action:'',
                file: null as File | null,
                folder: [] as File[] | null,
                showModal: false,
                modifiedCsvData: '' as string,
                needUploadMediaFile: false,
                mediaPaths:'',
                urlLists:[] as any,
                hasQuizGroup: false,
            };
        },
        computed: {
            actionOfCourse() {
                if(this.$router.currentRoute.path.includes('edit')) this.action = 'edit'
                else    this.action = 'create'
                // if(this.action == 'edit'){
                //     this.currentquiz = this.$store.state.currentQuizWorkspace
                // }
                this.currentquiz = this.$store.state.currentQuizWorkspace
                console.log(this.currentquiz.uuid)
            }
        },
        methods: {
            handleModalClose(){
                this.$bvModal.hide('bv-modal-a');
                this.file=null;
                this.folder=null;
                this.needUploadMediaFile=false
            },
            uploadFile(){
                this.showModal = true;
            },
            handleFileUpload(event: Event) {
                const input = event.target as HTMLInputElement;
                if (input.files?.length) {
                    this.file = input.files[0];
                    const reader = new FileReader();
                    reader.onload = async (e: ProgressEvent<FileReader>) => {
                        const csvData = e.target!.result as string;
                        this.processData(csvData);
                    };
                    reader.readAsText(this.file);
                }
            },
            async handleFolderUpload(event: InputFileEvent) {
                const input = event.target as HTMLInputElement;
                let files = input.files

                if (files && files.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                        // 通过分析路径判断文件是否在子目录中
                        interface WebkitRelativePathFile extends File {
                            webkitRelativePath: string;
                        }
                        let pathSegments = (files[i] as WebkitRelativePathFile).webkitRelativePath.split('/');
                        if (pathSegments.length > 2) { // 分割长度大于2说明文件在子目录中
                            alert("所選資料夾中包含其他資料夾，請重新選擇")
                            this.$nextTick(() => {
                                this.folder = null;
                            });
                            break;
                        }
                    }
                    this.folder = Array.from(files);
                }
            },
            processData(csvData: string) {
                Papa.parse(csvData, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results) => {
                        const data = results.data as Array<{ [key: string]: string }>;
                        data.forEach(row => {
                            if (row['quizType-类型'] === 'title') {
                                row['score-配分'] = '0'
                            }
                            row['titleImage-题目图片'] = row['titleImage-题目图片'].replace(/\\/g, '/');
                            row['titleAudio-题目音档'] = row['titleAudio-题目音档'].replace(/\\/g, '/');
                            row['answer1Image-答案1图片'] = row['answer1Image-答案1图片'].replace(/\\/g, '/');
                            row['answer1Audio-答案1音档'] = row['answer1Audio-答案1音档'].replace(/\\/g, '/');
                            row['answer2Image-答案2图片'] = row['answer2Image-答案2图片'].replace(/\\/g, '/');
                            row['answer2Audio-答案2音档'] = row['answer2Audio-答案2音档'].replace(/\\/g, '/');
                            row['answer3Image-答案3图片'] = row['answer3Image-答案3图片'].replace(/\\/g, '/');
                            row['answer3Audio-答案3音档'] = row['answer3Audio-答案3音档'].replace(/\\/g, '/');
                            row['answer4Image-答案4图片'] = row['answer4Image-答案4图片'].replace(/\\/g, '/');
                            row['answer4Audio-答案4音档'] = row['answer4Audio-答案4音档'].replace(/\\/g, '/');
                            row['answer5Image-答案5图片'] = row['answer5Image-答案5图片'].replace(/\\/g, '/');
                            row['answer5Audio-答案5音档'] = row['answer5Audio-答案5音档'].replace(/\\/g, '/');
                            });
                        this.modifiedCsvData = Papa.unparse(data, {
                            skipEmptyLines: true
                        });
                    }
                });
            },
            async uploadFolder(){
                interface WebkitRelativePathFile extends File {
                    webkitRelativePath: string;
                }
                if (this.folder) {
                    let UrlList;
                    let mediaPaths = [];
                    let err = 0 ;
                    const mediaFormData = new FormData();
                    for (const file of this.folder) {
                        for (let i=0; i < this.mediaPaths.length; i++) {
                            let parts = this.mediaPaths[i].split('/');
                            let pathName = parts.slice(-2).join('/');  // 'b/c.png'
                            // let pathName = this.mediaPaths[i].split('/').pop();
                            console.log("pathName: = ", pathName)
                            let relateivePath = (file as WebkitRelativePathFile).webkitRelativePath
                            console.log("relateivePath: = ", relateivePath)

                            if(relateivePath == pathName){
                                const mediaformData = new FormData();
                                mediaformData.append(`file`, file);
                                this.$store.commit('updateLoading', true);
                                err = 0;
                                try{
                                    UrlList = JSON.parse(JSON.stringify(await WorkspaceQuizApi.mediaUpload(this.$store.state.currentQuizWorkspace.uuid, mediaformData)));
                                    console.log("file relateivePath: " + relateivePath)
                                    console.log("UrlList: " + UrlList.url)
                                    mediaPaths.push(this.mediaPaths[i])
                                    this.urlLists.push(UrlList.url)
                                }catch(error){
                                    if (axios.isAxiosError(error)) {
                                        const errorData = error.response?.data;
                                        console.error("Error uploading media: ", errorData?.description || "No description");

                                        if (errorData && errorData.description === 'Unsupported media type') {
                                            alert(file.name + ' 是不支援的檔案，請給音檔或是圖檔');
                                        }
                                    } else {
                                        console.error("Error: ", error);
                                        alert('An unexpected error occurred.');
                                    }
                                    err = 1
                                    this.urlLists = [];
                                }finally{
                                    this.$store.commit('updateLoading', false);
                                }
                            }
                        }
                    }
                    if(err == 0 && this.urlLists.length==0) alert("上傳音檔圖檔錯誤，請檢查csv檔案中的本地音檔圖檔路徑是否存在於上傳的資料夾中，並確保資料夾中無夾帶其他資料夾。")
                    for (let i = 0; i < this.urlLists.length; i++){
                        mediaFormData.append('media-path', mediaPaths[i]);
                        mediaFormData.append('media-url', this.urlLists[i]);
                    }
                    if(!mediaFormData.entries().next().done)    this.csvUpload(mediaFormData)
                    else{
                        this.showModal = false;
                        this.needUploadMediaFile = false;
                        this.folder = null
                        this.file=null;
                    }
                }
            },
            async uploadSelectedFile(){
                if (!this.file) {
                    return;
                }
                const formData = new FormData();
                const mediaFormData = new FormData();
                const blob = new Blob([this.modifiedCsvData], { type: 'text/csv' });
                formData.append('update-csv', blob, this.file!.name);
                this.$store.commit('updateLoading', true);
                try{
                    const mediaList = JSON.parse(JSON.stringify(await WorkspaceQuizApi.listCSVMedia(this.$store.state.currentQuizWorkspace.uuid, formData)));
                    console.log("mediaList: " + mediaList.list)
                    if(mediaList.error == undefined){
                        if(mediaList.list == ''){
                            this.csvUpload(mediaFormData)
                        }
                        else{
                            this.needUploadMediaFile = true
                            this.mediaPaths = mediaList.list
                        }
                    }
                    else{
                        alert(mediaList.error+'\n請修正完畢後重新上傳')
                        this.showModal = false;
                        this.needUploadMediaFile = false;
                        this.file=null;
                        this.folder = null
                    }

                }catch(e){
                    console.log("Error listCSVMedia")
                    console.log(e)
                }finally{
                    this.$store.commit('updateLoading', false);
                }
            },
            async csvUpload(mediaFormData: FormData){
                // const formData = new FormData();
                const blob = new Blob([this.modifiedCsvData], { type: 'text/csv' });
                mediaFormData.append('update-csv', blob, this.file!.name);
                console.log("listCSVMedia status ok")
                this.$store.commit('updateLoading', true);
                try{
                    // if(this.isMediaFormDataEmpty(formData)){//no media data
                    const uploadStatus = JSON.parse(JSON.stringify(await WorkspaceQuizApi.csvUpload(this.$store.state.currentQuizWorkspace.uuid, mediaFormData)));
                    if(uploadStatus.ok=="200"){
                        alert("已經成功更新檔案")
                        window.location.reload();
                    }else{
                        alert(uploadStatus.error+'請修正完畢後再重新上傳。\n\nNote: 若為图像无效或是音頻无效，有可能是上傳的資料夾沒有包含題目檔案中所有的本地圖檔和音檔，請檢查的資料夾中是否已經包含所有檔案中的本地圖檔和音檔。\n')
                    }
                    this.showModal = false;
                    this.needUploadMediaFile = false;
                    this.folder = null
                    this.file=null;
                }catch(e){
                    console.log("Error csvUpload")

                }finally{
                    this.$store.commit('updateLoading', false);
                }
            },
            isMediaFormDataEmpty(formData: FormData): boolean{
                for (let key of formData.keys()) {
                    return false;
                }
                return true;
            },
            async downloadFile(){
                this.$store.commit('updateLoading', true);
                try{
                    const csvData = await WorkspaceQuizApi.downloadQuizGroup(this.$store.state.currentQuizWorkspace.uuid);
                    const blob = new Blob(["\uFEFF"+csvData], { type: 'text/csv;charset=utf-8;' });
                    const downloadUrl = window.URL.createObjectURL(blob);

                    const fileName = this.$store.state.currentQuizWorkspace.metadata.name.replace(/\s/g, '_');
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = '修正_'+fileName+'.csv';
                    document.body.appendChild(a);
                    a.click();

                    window.URL.revokeObjectURL(downloadUrl);
                    a.remove();
                }catch(e){
                    console.log("Error downloading")
                    console.log(e)
                }finally{
                    this.$store.commit('updateLoading', false);
                }
            },
            getCategoryId(id: string){
                this.currentquiz.categoryIds = id;
                console.log("this.currentquiz.categoryIds = " + this.currentquiz.categoryIds)
            },
            async saveEdit(){
                try{
                    this.$store.commit('updateLoading', true);
                    console.log("this.currentquiz.categoryIds = " + this.currentquiz.categoryIds)
                    const createQuiz = JSON.parse(JSON.stringify(await WorkspaceQuizApi.updateQuiz(this.currentquiz)));
                    this.$router.push({ path: "/workspace/quiz" });
                }catch(err){
                    console.log(err)
                }finally{
                    this.$store.commit('updateLoading', false);
                }
            },
        },
        async created(){
            this.actionOfCourse
            const answerResultInstance = JSON.parse(JSON.stringify(await AnswerGroupApi.getQuizGroupById(this.$store.state.currentQuizWorkspace.uuid)));
            if(answerResultInstance.detail.answerList.length > 0){
                console.log("has question")
                this.hasQuizGroup = true;
            }
            else    this.hasQuizGroup = false;
        }
    });
